<template>
    <div class="main-wrapper">
        <div class="teacher-top">
            <div class="top-left">
                <span class="left-title">教师管理</span>
            </div>
            <div class="top-right">
                <el-button type="primary" @click="addTeacher()">创建教师</el-button>
            </div>
        </div>
        <div class="major-screening">
            <span class="major-screening-title">专业筛选:</span>
            <el-select v-model="majorValue" placeholder="请选择" @change="teacherByclass($event)">
                <el-option
                        v-for="item in optionsCreenin"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <el-table :data="teacherTable" style="width: 100%; flex: 1" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="name" label="名称" align="left"></el-table-column>
            <el-table-column prop="major_name" label="专业" align="center"></el-table-column>
            <el-table-column prop="username" label="账号" align="center"></el-table-column>
            <el-table-column prop="head_portrait" label="头像" align="center">
                <template width="32" slot-scope="scope" class="avatar">
                    <img :src="scope.row.head_portrait?scope.row.head_portrait:require('../../assets/img/image/user_img.png')"/>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="260">
                <template slot-scope="scope">
                    <el-button size="small" @click="resetPassword(scope.row)">重置密码</el-button>
                    <el-button size="small" @click="editTeacher(scope.row)">编辑</el-button>
                    <el-button size="small" @click="deleteTeacher(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="teacherPages.currentPageNum"
                :page-size="teacherPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="teacherPages.total"
                @current-change="teacherCurrentChange">
        </el-pagination>
        <el-dialog :title="teacherTitleType" :visible.sync="dialogAddTeacher" center width="30%" @close="resetForm()"
                   @opened="openAddTeacher" :close-on-click-modal="false">
            <el-form :model="addTeacherForm" ref="addTeacherForm" :rules="rules" label-position="left" class="student-info">
                <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="addTeacherForm.name" ref="teacherInput" autocomplete="off" placeholder="请输入名称" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="账号" :label-width="formLabelWidth" prop="username">
                    <el-input v-model="addTeacherForm.username" v-if="this.teacherTitleType==='创建教师'" autocomplete="off" placeholder="请输入账号" style="width: 80%;"></el-input>
                    <el-input v-model="addTeacherForm.username" v-if="this.teacherTitleType==='编辑教师'" disabled autocomplete="off" placeholder="请输入账号" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item v-if="this.teacherTitleType==='创建教师'" label="密码" :label-width="formLabelWidth" prop="password">
                    <el-input v-model="addTeacherForm.password" type="password" autocomplete="off" placeholder="请输入密码" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="专业" :label-width="formLabelWidth" prop="major_id">
                    <el-select v-model="addTeacherForm.major_name" placeholder="请选择专业" @change="majorSelect($event)" style="width: 80%;">
                        <el-option
                                v-for="item in optionsCreenin"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                v-show="item.id>0">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="头像" :label-width="formLabelWidth" prop="image">
                    <template>
                        <input class="up-img" @change="changeFile" type="file" id="id_avatar" name="file" title/>
                        <el-button size="small" type="primary">{{addTeacherForm.image?'重新上传':'点击上传'}}</el-button>
                        <img :src="addTeacherForm.image" v-show="addTeacherForm.image" class="up-img-show" alt/>
                    </template>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" type="primary" @click="addToForm('addTeacherForm')">确 定</el-button>
                <el-button size="medium" @click="dialogAddTeacher = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "TeacherManage",
        data(){
            return{
                teacherTable:[],
                majorValue:'全部',
                optionsCreenin:[],
                addTeacherForm: {
                    name: '',
                    username: '',
                    major_name:'',
                    head_portrait: '',
                    password: '',
                    major_id:'',
                    image: '',
                    imageFile: '',
                },
                dialogAddTeacher: false,
                //弹窗标题
                teacherTitleType: '',
                formLabelWidth: '80px',
                //确定/保存
                distinguishBtn: '',
                rules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                        {min: 1, max: 12, message: '长度在 1 到12 个字符', trigger: 'blur'},
                    ],
                    username: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                        {min: 1, max: 12, message: '长度在 1 到12 个字符', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 1, max: 20, message: '长度在1到20个字符', trigger: 'blur'},
                    ],
                    major_id: [
                        {required: true, message: '请选择专业', trigger: 'change'},
                    ],
                },
                //分页
                teacherPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        created() {
            this.getTeacherList();
            this.getMajorList();
        },
        methods:{
            // 获取专业列表专业筛选
            getMajorList(){
                this.$http.axiosGet(this.$api.getMajor,(res) => {
                    if (res.code === 200) {
                            this.optionsCreenin = res.data.data;
                            let ob = {
                                id: 0,
                                name: "全部"
                            };
                            this.optionsCreenin.unshift(ob);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 获取教师列表信息
            getTeacherList(major_id){
                let param = {
                    page: this.teacherPages.currentPageNum,
                    pageSize: this.teacherPages.eachPageNum,
                    paging: 1,
                    type:3
                };
                if(major_id){
                    param.major_id = major_id
                }
                this.$http.axiosGetBy(this.$api.getTeacher,param, res =>{
                    console.log(res);
                        if (res.code === 200) {
                            console.log(res.data.data);
                            this.teacherTable = res.data.data;
                            this.teacherPages.total = res.data.total;
                        }
                    },
                    err => {
                        console.log(err);
                    }
                );
            },
            // 专业选择
            majorSelect(e) {
                this.addTeacherForm.major_id = e;
            },
            teacherByclass(e) {
                // 根据专业
                this.major_id = e;
                this.getTeacherList(this.major_id);
            },
            // 创建教师
            addTeacher() {
                this.dialogAddTeacher = !this.dialogAddTeacher;
                this.teacherTitleType = '创建教师';
                this.distinguishBtn = 'add';
            },
            editTeacher(row) {
                this.dialogAddTeacher = !this.dialogAddTeacher;
                this.teacherTitleType = '编辑教师';
                this.distinguishBtn = 'edit';
                this.addTeacherForm.id = row.id;
                this.addTeacherForm.username = row.username;
                this.addTeacherForm.name = row.name;
                this.addTeacherForm.password = row.password;
                this.addTeacherForm.major_id = row.major_id;
                this.addTeacherForm.major_name = row.major_name;
                this.addTeacherForm.image = row.head_portrait;
            },
            //新增修改教师
            addOrEdit(imageUrl){
                let formData = new FormData();
                formData.append("name", this.addTeacherForm.name);
                formData.append("username", this.addTeacherForm.username);
                formData.append("password", this.addTeacherForm.password);
                formData.append("major_id", this.addTeacherForm.major_id);
                formData.append("head_portrait", imageUrl);
                formData.append("type", 3);
                if (this.distinguishBtn === 'edit') {
                    formData.append("id", this.addTeacherForm.id);
                }
                // console.log(formData)
                this.$http.axiosPost(this.$api.getTeacher, formData, res => {
                        if (res.code === 200) {
                            this.$message({
                                type: "success",
                                message: res.message,
                                duration: 1000,
                                onClose: () => {
                                    this.dialogAddTeacher = !this.dialogAddTeacher;
                                    this.resetForm();
                                    this.getTeacherList();
                                }
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    }
                );
            },
            //添加/保存教师
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.addTeacherForm.imageFile) {
                            let fileOb = new FormData();
                            fileOb.append("file", this.addTeacherForm.imageFile);
                            this.$http.axiosPost(this.$api.upload, fileOb, res => {
                                if (res.code === 200) {
                                    this.addOrEdit(res.data)
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.message,
                                        duration: 1000
                                    });
                                }
                            })
                        } else {
                            this.addOrEdit(this.addTeacherForm.image)
                        }

                    }
                });
            },
            // 删除
            deleteTeacher(row) {
                // console.log(row,'666')
                this.$confirm('是否删除该数据，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$http.axiosPost(this.$api.delTeacher, {id: row.id,type:3}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.getTeacherList();
                        } else {
                            this.$message.warning(res.message)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            // 重置密码
            resetPassword(row) {
                this.$confirm("重置密码,默认123456", "提示消息", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true
                }).then(() => {
                    this.$http.axiosPost(this.$api.resetTeacherPwd,{id: row.id,type:3}, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.message);
                            } else {
                                this.$message.warning(res.message);
                            }
                        },
                        err => {
                            console.log(err);
                        }
                    );
                }).catch(() => {
                    this.$message.info("已取消重置");
                });
            },
            // 头像
            changeFile(e) {
                // console.log(123)
                if (this.beforeAvatarUpload(e.target.files[0])) {
                    this.addTeacherForm.imageFile = e.target.files[0];
                    var fr = new FileReader();
                    fr.onloadend = (e) => {
                        this.addTeacherForm.image = e.target.result;
                    };
                    fr.readAsDataURL(this.addTeacherForm.imageFile);
                } else {
                    document.getElementById("id_avatar").value = "";
                }
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg";
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error("上传头像图片只能是 JPG 格式!");
                }
                if (!isLt2M) {
                    this.$message.error("上传头像图片大小不能超过 2MB!");
                }
                return isJPG && isLt2M;
            },
            //重置表单
            resetForm() {
                this.$refs.addTeacherForm.resetFields();
                for (let key in this.addTeacherForm) {
                    this.addTeacherForm[key] = "";
                }
            },
            //打开弹窗聚焦输入框
            openAddTeacher() {
                this.$refs.teacherInput.focus();
            },
            //分页
            teacherCurrentChange(val) {
                this.teacherPages.currentPageNum = val;
                this.getTeacherList();
            },
        }
    }
</script>

<style scoped lang="scss">
    .teacher-top {
        display: flex;
        flex-wrap: wrap;
        padding:10px 20px;
        border-bottom: 1px solid #eaeaea;
        .top-left {
            width: 1%;
            flex: 1;
            .left-title {
                font-size: 14px;
                line-height: 40px;
                font-weight: 500;
            }
        }
    }
    .major-screening{
        padding:10px 0 10px 20px;
        .major-screening-title{
            font-size: 14px;
            margin-right:6px;
        }
    }
    img{
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    .up-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: 32px;
        opacity: 0;
    }
    .up-img-show{
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #eeeeee;
        vertical-align: middle;
        margin-left: 10px;
    }
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
        padding:10px 9px;
    }
</style>